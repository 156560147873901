<template>
  
  <q-layout view="hHh lpR fFf">

    <q-header bordered class="myip-header" >
      <q-toolbar>
        <router-link to="/">
        <q-avatar size="60px" class="myip-hal-logo" square>
          <img :src="logoSource" label="Hal, your friendly AI tour guide" alt="Hal, your friendly AI tour guide" width="60" height="60">
        </q-avatar>
        </router-link>
        
        <!--we need a H1 tag here somehow??-->
        
        <q-toolbar-title>
          <!--some kind of break for font size, also the text and teh light mode colours not right-->
          <q-item-label class="myip-logo-title">Welcome!</q-item-label>
          <q-item-label class="myip-logo-title-sub">..Internet traveller</q-item-label>
        </q-toolbar-title>
        <q-btn flat round dense :icon="darkModeIcon" @click="toggleDarkMode" aria-label="Toggle light/dark mode"></q-btn>
        <q-btn flat round dense size="lg" :icon="matMenu" aria-label="Menu">
          <q-menu auto-close anchor="center middle" self="top right">
            <q-list style="min-width: 100px">
              <q-item clickable to='/'>
                <q-item-section>Home</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item clickable href="https://knowledge-base.myip.ai/categories/travel-advice/">
                <q-item-section>Travel Guide DB</q-item-section>
              </q-item>
              <q-item clickable href="https://knowledge-base.myip.ai/categories/autonomous-system-numbers-asn/">
                <q-item-section>ASN Database</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item clickable href="https://knowledge-base.myip.ai/">
                <q-item-section>Knowledge Base</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item clickable @click="contact = true">
                <q-item-section>Contact</q-item-section>
              </q-item>
            </q-list>
          </q-menu>          
        </q-btn>
      </q-toolbar>

    </q-header>

    <q-page-container>
      <div class="flex flex-center column">
        <div class="row myip-main-container">
          <div id="parent" class="fit column wrap justify-center items-center content-center" style="overflow: visible;">
                          
        <!--insert router here (and app-->
          <router-view></router-view>
        
          </div>   
          
        </div>        

        <div class="row myip-footer">
        <q-btn label="Contact" color="primary" @click="contact = true" no-caps unelevated class="myip-contact-button" aria-label="show how to contact myip"></q-btn>
        <q-dialog v-model="contact">
          <q-card>
            <q-card-section class="myip-contact-header">
              <div class="text-h6 text-center">Contact Hal</div>
            </q-card-section>
            <q-separator></q-separator>
            <q-card-section class="row items-center myip-contact-content">
              <q-avatar size="100px"><img src="./assets/say-hello-to-hal.png" width="100" height="100"></q-avatar>
              <span class="myip-content-text">Questions / enquiries<br/>hal@myip.ai</span>
            </q-card-section>

            <q-card-actions align="center">
              <q-btn flat label="OK" color="primary" v-close-popup></q-btn>
            </q-card-actions>
          </q-card>
        </q-dialog>
        MyIP.ai &copy; 2024
        </div>   
      </div>

    </q-page-container>
  </q-layout>    
  
  <div v-if="notdevmode">
  <!-- no use for this at mo-->
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import { matLightMode, matDarkMode, matMenu } from '@quasar/extras/material-icons'
  import logoDark from './assets/logo2-100px.png';
  import logoLight from './assets/logo2L-100px.png';

  import { useQuasar } from 'quasar';
  const $q = useQuasar();
  
  const logoSource = ref('');
  const isDarkMode = ref($q.dark.isActive);
  const darkModeIcon = computed(() => {
    return isDarkMode.value ? matLightMode : matDarkMode;
  });

  const toggleDarkMode = () => {
    $q.dark.toggle();
    isDarkMode.value = $q.dark.isActive;
    localStorage.setItem('darkMode', $q.dark.isActive ? 'true' : 'false');
    if ($q.dark.isActive){
      logoSource.value = logoDark;
    }else{
      logoSource.value = logoLight;
    }
  };

  const setInitialDarkMode = () => {
    const storedPreference = localStorage.getItem('darkMode');
    if (storedPreference !== null) {      
      $q.dark.set(storedPreference === 'true');
    } else {
      const isSystemDarkMode = true; //overriding local pref, dark is better!
      //const isSystemDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      $q.dark.set(isSystemDarkMode);
      //initialise local storage with system preference
      localStorage.setItem('darkMode', $q.dark.isActive ? 'true' : 'false');
    }
    //synchronise icon 
    isDarkMode.value = $q.dark.isActive;
    if ($q.dark.isActive){
      logoSource.value = logoDark;
    }else{
      logoSource.value = logoLight;
    }
  };

  const contact =  ref(false);

  const notdevmode = ref(true);

  
  onMounted(async () => {    

    setInitialDarkMode();
    var devmode = process.env.NODE_ENV=='development';


    if (devmode){
      notdevmode.value = true;
    }

  });

  /* eslint-enable no-unused-vars */

</script>