<template>
  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square ><img src="../assets/guides.svg" width="40" height="40" alt="Your travel itinerary"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5">Travel Guide</q-item-label>
        <q-item-label caption>
          Where to go, what to see
        </q-item-label>
      </q-item-section>
      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide Travelk advice info panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>
    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list>
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="indigo-6" text-color="white" label="TRAVEL GUIDE" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="travelAdviceLoading">
                <q-item-label class="text-body2">Obtaining a travel guide for this location...</q-item-label>
              </div>
              <div v-else-if="travelAdvice">
                <q-item-label class="text-body1 text-weight-medium" v-html="travelAdvice"></q-item-label> 
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>              
            </q-item-section>
          </q-item>

          <div v-if="!travelAdviceLoading && travelAdvice">
            <q-separator></q-separator>
            <q-item class="myip-item-hasbadge">
              <q-badge color="indigo-6" text-color="white" label="PERMALINK" class="myip-badge"></q-badge>
              <q-item-section>
              <div class="travel-link">
                <div class="travel-link-details">
                  <q-item-label class="text-body1 text-weight-medium" ><span class="text-weight-medium"><a class="myip-external-link" :href="travelPermaLink" >Travel guide for: {{travelLocationFull}}</a></span></q-item-label>              
                </div>
                <div class="travel-link-copy">
                  <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(travelPermaLink)" aria-label="copy permanent link"></q-btn>
                </div>
              </div>
              </q-item-section>
            </q-item>
          </div>

        </q-list>
      </div>
    </q-slide-transition>
     <q-linear-progress :value="progress" aria-label="Travel guide is loading.."/>
  </q-card>
</template>

<style>
.travel-link{
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
}
.travel-link-details{
  display: flex;
  align-items: center;
  width:100%;
}
.travel-link-copy{
  width:42px;
}

</style>

<script setup>
  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matContentCopy, matKeyboardDoubleArrowUp, matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons';
  import { ref, onMounted, onUnmounted } from 'vue';

  const defaultvis = false;              //default for this card
  const localkey = 'travelVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  const props = defineProps({
    travelAdvice: String,
    travelAdviceLoading: Boolean,
    travelLocation: String,
    travelLocationFull: String,
    travelPermaLink:String,
  });

  
  const progress = ref(0);
  const step = 1/15;
  let interval;


  onMounted(() => {
    interval = setInterval(() => {
      if (progress.value < 1) {
        //console.log(props.travelAdviceLoading);
        if (!props.travelAdviceLoading){          
          progress.value = 1;
          clearInterval(interval); 
        }else{
          progress.value += step; // Increment by 10% every second
        }
      } else {
        clearInterval(interval); 
      }
    }, 1000); // 1000 milliseconds = 1 second


  });

  onUnmounted(() => {
    clearInterval(interval); // Clear the interval when the component is unmounted
  });

</script>
