import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue'; 
import PrivacyPage from './pages/PrivacyPage.vue'; 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPage,
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;