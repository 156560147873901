<template>
  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square><img src="../assets/ip-address.svg" width="40" height="40" alt="IP address information"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5">Your IP Address</q-item-label>
        <q-item-label caption>
          The unique identifier for your device on the Internet
        </q-item-label>
      </q-item-section>
      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide IP address panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>
    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list>
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="deep-orange-10" text-color="white" label="IPv4" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="ipv4Loading">
                <q-item-label class="text-body2">Detecting IPv4...</q-item-label>
              </div>
              <div v-else-if="ipv4">
                <q-item-label class="text-h5 text-weight-medium">{{ ipv4 }}<q-btn flat round color="primary" :icon="matContentCopy" @click="copy(ipv4)" aria-label="copy your ipv4 address"></q-btn></q-item-label>              
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected (try refreshing)</q-item-label>
              </div>              
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is ipv4"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <a href="https://knowledge-base.myip.ai/kb/ipv4/" label="What is IPv4?" class="myip-popover-link">IPv4</a> (Internet Protocol version 4) is a 32-bit addressing system used for internet communication. It represents addresses in dotted-decimal format (e.g., 192.168.1.1) and can support around 4.3 billion unique addresses. Due to address exhaustion, it's gradually being replaced by the 128-bit <a href="https://knowledge-base.myip.ai/kb/ipv6/" label="What is IPv6?" class="myip-popover-link">IPv6</a> system. Common features include private addressing and Network Address Translation (<a href="https://knowledge-base.myip.ai/kb/nat/" label="What is NAT?" class="myip-popover-link">NAT</a>).
              </q-menu>  
            </q-btn>
          </q-item>
          
          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="deep-orange-10" text-color="white" label="IPv6" class="myip-badge"></q-badge>
            <q-item-section class="myip-itemsection-wrapfix">
              <div v-if="ipv6Loading">
                <q-item-label class="text-body2">Detecting IPv6...</q-item-label>
              </div>
              <div v-else-if="ipv6">
                <q-item-label class="text-h6">{{ ipv6 }}<q-btn flat round color="primary" :icon="matContentCopy" @click="copy(ipv6)" aria-label="copy your ipv6 address"></q-btn></q-item-label>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is ipv6"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <div v-if="ipv6">
                <a href="https://knowledge-base.myip.ai/kb/ipv6/" label="What is IPv6?" class="myip-popover-link">IPv6</a> (Internet Protocol version 6) is a 128-bit addressing system designed to replace the limited IPv4. It offers a vast number of unique addresses using hexadecimal notation (e.g., 2001:0db8:85a3:0000:0000:8a2e:0370:7334). With built-in security features and enhanced performance, IPv6 addresses the challenges of <a href="https://knowledge-base.myip.ai/kb/ipv4/" label="What is IPv4?" class="myip-popover-link">IPv4</a> address exhaustion and supports the expanding internet. 
                </div>
                <div v-else>
                <a href="https://knowledge-base.myip.ai/kb/ipv6/" label="What is IPv6?" class="myip-popover-link">IPv6</a> Not Detected: While IPv6 is the successor to IPv4, not all networks and service providers have adopted it yet. You may not be assigned an IPv6 address due to your device settings, network infrastructure, or if your internet provider doesn't support it. IPv4 continues to be widely used alongside IPv6.
                </div>
              </q-menu>  
            </q-btn>
          </q-item>
          
          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge-xtra">
            <q-badge color="deep-orange-10" text-color="white" label="ISP" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">
                <q-item-label class="text-body2">Detecting ISP...</q-item-label>
              </div>
              <div v-else-if="isp">
                <q-item-label class="text-body2 ">Internet Service Provider:</q-item-label>
                <q-item-label class="text-body1 text-weight-medium">{{ isp }} </q-item-label>
                <q-item-label class="text-body2">{{ country }} </q-item-label>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is an ISP"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                An <a href="https://knowledge-base.myip.ai/kb/isp/" label="What is an ISP?" class="myip-popover-link">ISP</a> (Internet Service Provider) is an organization that provides services for accessing, using, or participating in the Internet. ISPs can be commercial, non-profit, or community-owned, and offer various services including broadband, DSL, dial-up, and mobile data. They play a central role in connecting users to the global internet and managing data traffic.
              </q-menu>  
            </q-btn>
          </q-item>
          
        </q-list>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script setup>
  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matContentCopy, matKeyboardDoubleArrowUp,  matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons'
  import { ref } from 'vue';
  
  const defaultvis = true;              //default for this card
  const localkey = 'infoVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  defineProps({
    ipv4: String,
    ipv4Loading: Boolean,
    ipv6: String,
    ipv6Loading: Boolean,
    isp:String,
    isplink:String,
    country:String,
    extendedLoading:Boolean,
  });

</script>
