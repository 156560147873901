<template>

  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square ><img src="../assets/location.svg" width="40" height="40" alt="Approximate location information"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5" v-html="locationtitle"></q-item-label>
        <q-item-label caption v-html="locationcaption1"></q-item-label>
        <q-item-label caption v-html="locationcaption2" @click="getLoc()"></q-item-label>
      </q-item-section>
      <div v-if="locationrefresh">
        <q-item-section>
          <q-spinner-orbit color="primary" size="md" class="myip-locspin-working"></q-spinner-orbit>
        </q-item-section>
      </div>

      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide IP location panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>

    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list>
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge-xtra">
            <!--needs some kind of test for empty extra location and all the rest, see 2.58.94.0 (but will we evr get here anywya-->
            <q-badge color="green-10" text-color="white" label="LOCATION (APPROX)" class="myip-badge" v-html="locationbadge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">Processing ...</div>
              <div v-else>
                <div v-if="havelocation==1">
                  <q-item-label class="text-body1" v-if="extrapresent && extra.enLabel && extra.enDescription">{{extra.enLabel}}, {{extra.enDescription}}</q-item-label>
                </div>
                <div v-else>
                  <q-item-label class="text-body2">not detected</q-item-label>
                </div>
              </div>
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on location accuracy"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Location:</strong> This is your approximate position, derived from network signals like your IP address. It's important to remember that geolocation isn't entirely accurate and can't pinpoint your exact location. It provides a general idea of your area, useful for region-specific services or content. However, variations in network routing and IP allocation mean that this information should be considered as approximate rather than precise positioning.
              </q-menu>  
            </q-btn>
          </q-item>
          <q-item>
            <q-item-section>
              <div class="location-wrapper">
                <div class="location">
                  <div v-if="!extendedLoading">
                    <div v-if="havelocation==1">
                      <div class="location-address">
                        <div class="location-address-details">
                          <span v-html="getAddress(true)"></span>
                        </div>
                        <div class="location-address-copy">
                          <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(getAddress(false))" aria-label="copy location address"></q-btn>
                        </div>
                      </div>       
                      <q-separator class="myip-separator"></q-separator>
                      <div class="location-coords">
                        <div class="location-coords-details">
                          <q-item-label class="text-body2" v-if="latitude">Coordinates: <span class="text-weight-medium">{{ latitude }}, {{ longitude }}</span></q-item-label>
                        </div>
                        <div class="location-coords-copy">
                          <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(latitude + ',' + longitude)" aria-label="copy location coordinates"></q-btn>
                        </div>
                      </div>
                      <q-separator class="myip-separator"></q-separator>
                      <div class="location-summary">
                        <q-item-label class="text-body2" v-if="extrapresent && extra.FreebaseID">Summary: <span class="text-weight-medium"><a class="myip-external-link" :href="summaryLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">[Google]</a></span></q-item-label>
                        <q-item-label class="text-body2" v-if="extrapresent && extra.OpenStreetMapID">OpenStreetMap: <span class="text-weight-medium"><a class="myip-external-link" :href="osmLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">[Map]</a></span></q-item-label>
                        <q-item-label class="text-body2">Weather: <span class="text-weight-medium"><a class="myip-external-link" :href="weatherLink(latitude, longitude)" target="_blank" rel="noopener, noreferrer, nofollow">[Forecast]</a></span></q-item-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="images.length>0">
                  <div class="gallery">
                    <div class="image-container">
                      <q-img
                        :src="`${basepath}${images[currentIndex].image}`"
                        :key="images[currentIndex].image"
                        :alt="`Location image ${currentIndex + 1}`"
                        no-transition
                        height="100%"
                        width="100%"
                        fit="contain"
                        loading="eager"
                        @click="goToSource(images, currentIndex)"
                      >
                      </q-img>
                    </div>
                    <div class="text-container" @click="goToSource(images, currentIndex)">
                      {{images[currentIndex].desc}}
                    </div>
                    <div class="navigaton-container">              
                      <q-btn v-for="(image, index) in images" :key="image" :icon="imgIcon(index)" @click="currentIndex=index" :aria-label="`Location image button ${index + 1}`" class="" round dense size="8px"></q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </q-item-section>
          </q-item>

          <div v-if="extrapresent && (extra.OfficialSite || extra.Population || extra.Elevation || extra.Area)">
            <q-separator></q-separator>      
            <q-item class="myip-item-hasbadge-xtra">
              <q-badge color="green-10" text-color="white" label="LOCATION DETAILS" class="myip-badge"></q-badge>
              <q-item-section>
                <q-item-label class="text-body1" v-if="extrapresent && extra.OfficialSite!=''">Official site: <span class="text-weight-medium"><a class="myip-external-link" :href="offsiteLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">{{ extra.OfficialSite }}</a></span></q-item-label>
                <q-item-label class="text-body1" v-if="extrapresent && extra.Population!=''">Population: <span class="text-weight-medium">{{ populationText(extra) }}</span></q-item-label>
                <q-item-label class="text-body1" v-if="extrapresent && extra.Elevation!=''">Elevation: <span class="text-weight-medium">{{ extra.Elevation }}m</span></q-item-label>
                <q-item-label class="text-body1" v-if="extrapresent && extra.Area!=''">Area: <span class="text-weight-medium">{{ extra.Area }}</span></q-item-label>
                <q-item-label class="text-body1" v-if="timezone">Timezone: <span class="text-weight-medium">{{ timezone }}</span></q-item-label>
              </q-item-section>
            </q-item>
          </div>
          
        </q-list>
      </div>
    </q-slide-transition >
 
  </q-card>

</template>

<style>

.q-img__content>div {
  padding:4px 4px 4px 4px !important;
}
.location-header{
  width:100%;
  margin-bottom: 16px;
}
.location-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.location{
  display: flex;  
  flex-direction: column;
  width:100%;
}

.location-address{
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.location-address-details{
  width:100%;
}
.location-flag{
  margin-left:16px;
  border: 1px solid #444;
  vertical-align: middle;  
}
.location-address-copy{
  width:42px;
}

.location-coords{
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
}
.location-coords-details{
  display: flex;
  align-items: center;
  width:100%;
}
.location-coords-copy{
  width:42px;
}

.location-summary{
  width:100%;
  margin-top: 8px;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:150px;
  margin:0px 0px 0px 16px;
  border: 1px solid rgb(25, 118, 210); /*primary*/
  border-radius:2%;
}
.image-container {
  height: 150px; /* Set fixed height */
  width: 150px; /* Set fixed width if desired */
  overflow: hidden; 
  cursor: pointer;
  padding: 3px 3px;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;
  height:53px;
  width:150px;  
  font-size:0.75rem;
  line-height:1rem;
  padding:1px 3px;
  cursor: pointer;
}
.navigaton-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%; /* Full width of the container */
  max-width: 150px; /* Match the image width */
  padding: 8px 1px 8px 1px;
  background:rgb(25, 118, 210); /*primary*/
  
}

.gallery{
    color: #eee;
    background: #002433;
}
body.body--dark .gallery{
    color: #eee;
    background: #345;
}


</style>

<script setup>
  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matRadioButtonChecked, matRadioButtonUnchecked, matContentCopy, matKeyboardDoubleArrowUp,  matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons'
  import { ref } from 'vue';

  const defaultvis = true;              //default for this card
  const localkey = 'locationVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  const props = defineProps({
    havelocation: Number,
    locality: String,
    subdiv: String,
    city: String,
    country: String,
    countryiso: String,
    continent: String,
    postcode: String,
    latitude: Number,
    longitude: Number,
    timezone: String,
    images: Array,
    extra: Object,
    extrapresent: Boolean,
    basepath: String,
    extendedLoading: Boolean,
    locationrefresh:Boolean,
    locationtitle:String,
    locationcaption1:String,
    locationcaption2:String,
    locationbadge:String,
  });
  const emit = defineEmits([
    'getloc'
  ]);

  const summaryLink = (extra) => {
    return "https://www.google.com/search?kgmid=" + extra.FreebaseID;
  };
  const osmLink = (extra) => {
    return "https://www.openstreetmap.org/relation/" + extra.OpenStreetMapID;
  };
  const offsiteLink = (extra) => {
    return extra.OfficialSite;
  };
  const weatherLink = (latitude, longitude) => {
    return "https://www.visualcrossing.com/weather-forecast/" + latitude + ',' + longitude + '/metric';
  };

  

  const populationText = (extra) => {    
    var pop = extra.Population;
    if (extra.PopDateStamp){
      pop += ' (' + extra.PopDateStamp + ')';
    }
    return pop;
  };

  const getAddress = (mode) => {
    var retval='';
    var n = '';
    var img = '';
    if (mode){
      n = '<br />';
      if (props.countryiso){
        img = "<img class='location-flag' src='" + props.basepath + "flags/" + props.countryiso.toLocaleLowerCase() + "32.png' alt='" + props.country + " flag' label='" + props.country + " flag' width='24' height='16'>";
      }
    }else{  
      n = ', ';
    }
    retval += props.locality + n ;
    if (props.city!=props.locality){
      if (props.city){
        retval += props.city + n;
      }
    }
    if (props.subdiv){
      retval += props.subdiv + ' ';
    }
    if (props.postcode) {
      retval += props.postcode;
    }    
    retval += n + props.country + img;

    return retval;
  };

  const imgIcon = (index) => {
    return (currentIndex.value==index) ? matRadioButtonChecked : matRadioButtonUnchecked;
  };

  const currentIndex = ref(0);

  const goToSource = (images) => {
    window.open(images[currentIndex.value].attrib, '_blank', 'noopener, noreferrer');
  };

  const getLoc = () => {
    emit('getloc');
  };


</script>
