<template>
  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square><img src="../assets/ip-network.svg" width="40" height="40" alt="IP network information"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5">IP Address Details</q-item-label>
        <q-item-label caption>
          From ISP to port: your connection blueprint.
        </q-item-label>
      </q-item-section>
      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide IP extended info panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>
    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list >
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="cyan-10" text-color="white" label="NETWORK" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">
                <q-item-label class="text-body2">Detecting Network...</q-item-label>
              </div>
              <div v-else-if="network">
                <q-item-label class="text-body1 text-weight-medium">{{ network }} / {{ prefix }}<q-btn flat round color="primary" :icon="matContentCopy" @click="copy(network + '/' + prefix)" aria-label="copy network info"></q-btn></q-item-label>              
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>              
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is a network block"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Network Block:</strong> This is a representation of a range of IP addresses using CIDR notation. In '{{ network }} / {{ prefix }}', '{{ network }}' is the starting IP address of the range, while '/{{ prefix }}' indicates the subnet mask length, determining the number of IPs in this block. It's a compact way to specify IP ranges and is crucial for efficient routing, network configurations, and IP address management in both IPv4 and IPv6 environments. This notation aids administrators in subnetting and allocating IP resources.
              </q-menu>  
            </q-btn>
          </q-item>
          <div v-if="network">
            <q-item class="myip-item-dense">
              <q-item-section>                
                <q-item-label class="text-body2">IP Range: <span class="text-weight-medium">{{ rangestart }} - {{ rangeend }}</span></q-item-label>
                <q-item-label class="text-body2">Total Addresses: <span class="text-weight-medium">{{ addresses_total }}</span></q-item-label>
                <q-item-label class="text-body2">Useable Addresses: <span class="text-weight-medium">{{ addresses_useable }}</span></q-item-label>
              </q-item-section>
              <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info about ip ranges"> 
                <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
                <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                  <div v-if="prefix===32">
                    A /32 IP address represents a singular address with no additional addresses in its range. Commonly used for specific routing rules or firewall configurations, it serves as an exclusive identifier in the network. With no separate network or broadcast address, it's uniquely designated for individual purposes, ensuring precise communication.
                  </div>
                  <div v-else-if="prefix===31">
                    The /31 subnet is an exception in traditional IP addressing, allowing both its IP addresses for use in point-to-point links. While traditional subnets reserve addresses for network identification and broadcasting, RFC 3021 permits /31 subnets to utilize both IPs, optimizing address use for direct device-to-device connections.
                  </div>
                  <div v-else>
                    This IP range represents a set of addresses with both network and broadcast identifiers at the start and end respectively. The total indicates the combined count of network, broadcast, and usable addresses. The usable count excludes the network and broadcast, presenting addresses available for devices. Ideal for larger networks, it ensures efficient distribution and connectivity.
                  </div>
                </q-menu>  
              </q-btn>
            </q-item>        
          </div>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge-xtra">
            <q-badge color="cyan-10" text-color="white" label="ASN" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">
                <q-item-label class="text-body2">Detecting ASN...</q-item-label>
              </div>
              <div v-else-if="asn">
                <q-item-label class="text-body2 ">Autonomous System Number:</q-item-label>
                <q-item-label class="text-body1 text-weight-medium" ><span class="text-weight-medium"><a class="myip-external-link" :href="asnLink(asn)" >{{'AS'+asn }}</a></span></q-item-label>              
                <div v-if="isplink==''">
                  <q-item-label class="text-body2" style="margin-top:4px">ISP: {{ isp }}</q-item-label>
                </div>
                <div v-else>
                  <q-item-label class="text-body2" style="margin-top:4px">ISP: <span class="text-weight-medium"><a class="myip-external-link" :href="kbispLink(isplink)" >{{ isp }}</a></span></q-item-label>
                </div>                  
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>    
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is an ASN"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong><a href="https://knowledge-base.myip.ai/kb/asn/" label="What is an ASN?" class="myip-popover-link">ASN</a> (Autonomous System Number):</strong> A unique identifier allocated to each Autonomous System (AS) on the internet. An AS consists of a group of IP networks operated by one or more Internet Service Providers (<a href="https://knowledge-base.myip.ai/kb/isp/" label="What is an ISP?" class="myip-popover-link">ISP</a>) that have a unified routing policy. ASNs facilitate routing and peering decisions on the global internet, ensuring data packets reach their intended destinations. 
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="cyan-10" text-color="white" label="CLIENT PORT" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">
                <q-item-label class="text-body2">Detecting Client Port...</q-item-label>
              </div>
              <div v-else-if="clientPort">
                <q-item-label class="text-body1 text-weight-medium">{{ clientPort }}</q-item-label>          
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>    
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is a client port"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Client Port:</strong> The specific communication endpoint on a client device within its IP address. Detected using the 'X-Forwarded-Port' header, it represents the port through which your device connected to this service. Ports help differentiate and manage multiple network connections simultaneously on a single device.
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="cyan-10" text-color="white" label="PRIVATE IP" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="privateIpLoading">
                <q-item-label class="text-body2">Detecting Private IP...</q-item-label>
              </div>
              <div v-else-if="privateIp">
                <q-item-label class="text-body1 text-weight-medium">{{ privateIp }}</q-item-label>          
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>    
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is a private IP address"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Private IP:</strong> This is your device's internal address within your local network, often assigned by a router or DHCP server. It's used for local communications within your home or business network, distinct from your public IP which represents you on the wider internet. While this IP provides insights into your network structure, it's not directly reachable from the external internet.
              </q-menu>  
            </q-btn>
          </q-item>
        </q-list>
      </div>
    </q-slide-transition>


  </q-card>
</template>

<script setup>
  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matContentCopy, matKeyboardDoubleArrowUp,  matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons'
  import { ref } from 'vue';
  
  //whatismyip.com copies a link (to a page explaining address info etc) not just the text
  //do we want to do this?

  const defaultvis = true;              //default for this card
  const localkey = 'extendedVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  const asnLink = (asn) => {
    return "https://knowledge-base.myip.ai/categories/kb/AS" + asn;
  };

  const kbispLink = (isplink) => {
    return isplink;
  }
  
  defineProps({
    ipv4: String,
    ipv4Loading: Boolean,
    network: String,
    prefix: Number,
    addresses_total: Number,
    addresses_useable: Number,
    rangestart: String,
    rangeend: String,
    isp: String,
    isplink:String,
    asn: Number,
    ipv6: String,
    ipv6Loading: Boolean,
    privateIp: String,
    privateIpLoading: Boolean,
    clientPort: String,
    extendedLoading: Boolean,
  });

</script>
